<template>
  <div>
    <Base :title="title" titleIcon="el-icon-s-claim">

    <el-tabs v-model="activeName" type="border-card" class="form-panel">
      <el-tab-pane label="计划书" name="second">
        <el-form ref="refForm" :model="form" label-width="180px" :disabled="!!form.id" class="permissionPlan">
          <el-form-item label="计划书编号" prop="code">

            <el-form :model="form">
              <el-space>
                <el-radio-group v-model="form.permissionEmpty">
                  <el-radio :label="false" :disabled="form.permissionStatus != null">有</el-radio>
                  <el-radio :label="true" :disabled="form.permissionStatus != null">无</el-radio>
                </el-radio-group>
                <template v-if="!form.permissionEmpty">
                  <el-input v-model="form.code" placeholder="请填写计划书编号" style="width: 400px;">

                    <template #append> <el-button type="primary" @click="doCodeBind">读取</el-button></template>
                  </el-input>
                </template>
              </el-space>
            </el-form>

          </el-form-item>

          <el-row>
            <el-col :span="24">
              <el-space :size="10" style="vertical-align: top">
                <el-form-item label="文保单位" prop="heritageUnit_Id">
                  <SelectUnit :setting="selectUnitSetting" v-model="form.heritageUnit_Id" @change="selectUnitChange">
                  </SelectUnit>
                </el-form-item>
                <el-form-item label="级别">
                  {{
                    dicLabel(
                      currentHeritage.heritageLevel_Code,
                      heritageLevelDic
                    )
                  }}
                </el-form-item>
                <el-form-item label="类型">
                  {{
                    dicLabel(
                      currentHeritage.heritageClass_ID,
                      dicHeritageClass
                    )
                  }}
                </el-form-item>
              </el-space>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="批次" prop="heritageBatch_ID">
                <el-select v-model="form.heritageBatch_ID">
                  <el-option v-for="item in dicHeritageBatch" :key="item.id" :value="parseInt(item.value)"
                    :label="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否革命文物" prop="isRevolution">
                <el-switch v-model="form.isRevolution" active-text="是" inactive-text="否" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="类型" prop="isSafeProject">
            <div>
              <el-radio-group v-model="form.isSafeProject">
                <el-radio :label="0">保护工程</el-radio>
                <el-radio :label="1">三防(安防消防防雷)</el-radio>
                <el-radio :label="2">考古调查勘探</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <template v-if="form.isSafeProject == 1">
            <el-form-item label="工程类型" prop="safeProjectType">
              <el-checkbox-group v-model="form.safeProjectType">
                <el-checkbox :label="item.value" v-for="item in scheduleTypeDic.filter(
                  (p) => p.ext.projectType == form.isSafeProject
                )" :key="item.value">{{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="form.isSafeProject == 0 ? '保护工程类型' : '考古目的'" prop="projectType">
              <el-radio-group v-model="form.projectType">
                <el-radio :label="item.value" v-for="item in scheduleTypeDic.filter(
                  (p) => p.ext.projectType == form.isSafeProject
                )" :key="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </template>



          <el-form :model="form" label-width="180px">
            <el-form-item label="资金来源" prop="fundSource">
              <el-select v-model="form.fundSource" placeholder="请选择">
                <el-option label="财政资金" value="财政资金"></el-option>
                <el-option label="自筹资金" value="自筹资金"></el-option>
                <!-- <el-option label="其他" value="其他"></el-option> -->
              </el-select>
            </el-form-item>

            <el-form-item label="项目名称" prop="_projectName">
              <el-input v-model="form._projectName" placeholder="项目名称" :disabled="false"></el-input>
            </el-form-item>

            <el-form-item label="是否分为多个项目实施" prop="_splitProject">
              <el-select v-model="form._splitProject" placeholder="请选择">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="管理使用单位" prop="_ownerUnit">
              <el-input v-model="form._ownerUnit" placeholder="管理使用单位" :disabled="false"></el-input>
            </el-form-item>

            <el-form-item label="立项批复文件标题" prop="_setupDocTitle">
              <el-input v-model="form._setupDocTitle" placeholder="立项批复文件标题" :disabled="false"></el-input>
            </el-form-item>

            <el-form-item label="立项批复文号" prop="_setupDocCode">
              <el-input v-model="form._setupDocCode" placeholder="立项批复文号" :disabled="false"></el-input>
            </el-form-item>

            <el-form-item label="立项批复时间" prop="_setupDocDate">
              <el-input v-model="form._setupDocDate" placeholder="立项批复时间" :disabled="false"></el-input>
            </el-form-item>

            <el-divider content-position="left">方案信息</el-divider>

            <el-form-item label="方案名称" prop="_planName">
              <el-input v-model="form._planName" placeholder="方案名称" :disabled="false"></el-input>
            </el-form-item>
            <el-form-item label="方案委托编制时间" prop="_planDesignDate">
              <el-input v-model="form._planDesignDate" placeholder="方案委托编制时间" :disabled="false"></el-input>
            </el-form-item>


            <el-form-item label="方案编制单位">

              <el-form-item label="单位名称" prop="planDesignUnit">
                <el-input v-model="form.planDesignUnit" placeholder="方案设计单位名称" :disabled="false"></el-input>
              </el-form-item>

              <el-form-item label="资质类型" prop="_planDesignUnitProp">

                <el-select v-model="form._planDesignUnitProp" placeholder="请选择">
                  <el-option label="勘察设计甲级" value="勘察设计甲级"></el-option>
                  <el-option label="勘察设计乙级" value="勘察设计乙级"></el-option>
                  <el-option label="无" value="无"></el-option>
                </el-select>


              </el-form-item>
              <el-form-item label="业务范围" prop="_planDesignUnitBA">
                <el-input v-model="form._planDesignUnitBA" placeholder="业务范围" :disabled="false"></el-input>
              </el-form-item>
            </el-form-item>

            <el-form-item label="方案编制负责人">

              <el-form-item label="姓名" prop="_planDesignUnitPerson">
                <el-input v-model="form._planDesignUnitPerson" placeholder="方案编制负责人姓名" :disabled="false"></el-input>
              </el-form-item>
              <el-form-item label="是否为责任设计师" prop="_planDesignUnitPropirty">
                <el-select v-model="form._planDesignUnitPropirty" placeholder="请选择">
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="专业资格类型" prop="_planDesignUnitPersonType">
                <el-input v-model="form._planDesignUnitPersonType" placeholder="专业资格类型" :disabled="false"></el-input>
              </el-form-item>


              <el-form-item label="专业证书编号" prop="_planDesignUnitPersonDocCode">
                <el-input v-model="form._planDesignUnitPersonDocCode" placeholder="专业资格类型" :disabled="false"></el-input>
              </el-form-item>

              <el-form-item label="从业范围" prop="_planDesignUnitPersonBA">
                <el-input v-model="form._planDesignUnitPersonBA" placeholder="从业范围" :disabled="false"></el-input>
              </el-form-item>

            </el-form-item>



          </el-form>


          <el-form-item label="相关附件" prop="attachList">
            <div>(可根据实际情况另附文字说明、图纸等其他材料)</div>

            <FileuploadList :setting="fileuploadSetting" @upload="() => refForm.clearValidate('attachList')">
            </FileuploadList>
          </el-form-item>

          <el-form-item label="项目审批计划书" prop="setupFile">
            <FileuploadListV1 v-model:files="form.attachList" fileGroup="setupFile"
              accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
              @upload="() => refForm.clearValidate('setupFile')">
            </FileuploadListV1>
          </el-form-item>

          <el-form-item label="许可批复文件" prop="setupDocId">
            <OffcialDocSelectAndCreate :category="4" v-model="form.setupDocId"></OffcialDocSelectAndCreate>
          </el-form-item>


          <el-form-item label="编制单位" prop="contactPerson">
            <el-space>
              <el-input v-model="form.contactPerson" placeholder="联系人"></el-input>
              <el-input v-model="form.contactPhone" placeholder="电话"></el-input>
            </el-space>
          </el-form-item>

          <el-form-item label="">
            <p>
              填报说明：填报的设计单位和项目负责人信息与项目方案文本上的信息一致。
              方案中设计单位公章、报审章、项目负责人资质章齐全；
              项目负责人资质业务范围应与项目内容匹配。
            </p>

          </el-form-item>
          <el-form-item label="填报时间">{{
            parseTime(form.writeDate, "{yyyy}年{mm}月{dd}日")
          }}</el-form-item>

        </el-form>


      </el-tab-pane>


      <el-tab-pane label="审核" name="revivew" v-if="form.permissionStatus > 0">
        
        <Review @submit="onReviewSubmit">
          <template v-if="form.permissionStatus >= 2">
            <el-divider content-position="left">方案批复信息</el-divider>

            <el-form-item label="核准后文物保护工程方案" prop="planFile" label-width="140px">
              <FileuploadListV1 v-model:files="form.attachList" fileGroup="planFile"
                accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
                @upload="() => refForm.clearValidate('planFile')">
              </FileuploadListV1>
            </el-form-item>

            <el-form-item label="方案批复文件" prop="planDocId"  label-width="140px">
              <OffcialDocSelectAndCreate :category="2" v-model="form.planDocId"></OffcialDocSelectAndCreate>
            </el-form-item>



            <el-form-item label="方案修改说明" prop="planFileEditDoc"  label-width="140px">
              <FileuploadListV1 v-model:files="form.attachList" fileGroup="planFileEditDoc"
                accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
                @upload="() => refForm.clearValidate('planFileEditDoc')">
              </FileuploadListV1>
            </el-form-item>

          </template>
        </Review>
      </el-tab-pane>
      <el-tab-pane label="操作记录" name="logs" :disabled="!logs || logs.length == 0">
        <LogHistory :data="logs"></LogHistory>
      </el-tab-pane>
    </el-tabs>

    </Base>

    <div class="pageaa-footer">
      <div class="button">
        <el-button icon="el-icon-back" @click="$router.push('/PermissionPlan/Index')">返回</el-button>

        <el-divider direction="vertical"></el-divider>
        <!-- <el-button icon="el-icon-arrow-right" @click="nextTo()" v-if="activeName == 'first'" type="primary">下一步
        </el-button> -->

        <template v-if="activeName == 'second'">
          <!-- <el-button icon="el-icon-arrow-left" @click="activeName = 'first'" type="primary">上一步</el-button> -->
          <el-button type="success" icon="el-icon-check" @click="doSave(false)" :loading="isLoading || isFileUploading">
            保 存</el-button>
          <el-popconfirm title="确认提交吗！？" @confirm="doSave(true)" placement="top" v-if="form.permissionStatus == 0">
            <template #reference>
              <el-button type="primary" :loading="isLoading || isFileUploading">
                {{ isLoading ? "处理中" : "提交" }}
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ElMessage } from "element-plus";
import FileuploadList from "@/components/fileuploadList.vue";
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import { useCityRelTown } from "@/network/chinaArea";
import { parseTime } from "@/utils/index.js";
import {
  dicLabel,
  useSelectItemList,
  useSelectItemWithChildList,
  scheduleTypeDic,
} from "@/network/lookUp.ts";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  inject,
} from "vue";
import Base from "../layout/Base.vue";
import { useSetupProject, useQueryPermissionActionHistory } from "@/network/setupProject";
import roles from "@/types/roles.ts";
import { useFindHeritage } from "@/network/heritageUnit";
import { useRouter } from "vue-router";
import Review from "@/views/shared/review.vue";
import LogHistory from "@/views/shared/logHistory.vue";
import OffcialDocSelectAndCreate from "../officialDoc/offcialDocSelectAndCreate.vue";
export default defineComponent({
  name: "projectCreate",
  components: { Base, SelectUnit, FileuploadList, Review, LogHistory, FileuploadListV1, OffcialDocSelectAndCreate },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    // const form: any = reactive({
    //   code: "待编号",
    //   project_Name: "",
    //   historyProjects: [],
    //   writeDate: new Date(),
    //   attachList: [],
    //   safeProjectType: []
    // });
    const router = useRouter();
    const user: any = inject("user");
    const refForm = ref(null);

    const city_IDs = [176, 177, 181];

    const id: any = ref(props.id);
    const isFileUploading = ref(false);
    const [isLoading, form, find, , , , findByCode, permissionSave, permissionReview] = useSetupProject(id);
    const [logs, queryHistory] = useQueryPermissionActionHistory(id);
    const [, , findHeritage] = useFindHeritage();

    const fileuploadSetting = reactive({
      accpet: ".pdf,.jpg,.bmp,.gif,.tif,.zip,.rar,.xls,.xlsx,.doc,.docx",
      urlKey: "filePath",
      nameKey: "fileName",
      isFileUploading,
      typeGroup: ref({
        list: form.attachList,
        type: "附件",
      }),
    });

    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });
    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "cityId"),
      toRef(form, "countryId")
    );

    const heritageLevelDic = useSelectItemList("HeritageLevel");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");
    const [project_ClassifyDic, project_SubClassifyDic] =
      useSelectItemWithChildList(
        toRef(form, "project_Classify_Id"),
        toRef(form, "project_SubClassify_Id"),
        "ProjectClassify",
        false
      );

    const title = computed(() => {
      let str = currentHeritage.value.heritage_Name || "";
      str += ` ${dicLabel(form.projectType, scheduleTypeDic) || ""}计划书`;
      return `[${form.code}] ${str}  `;
    });
    const activeName = ref("second");

    const historyFunds = ref([]);
    const currentHeritage: any = ref({});
    const selectUnitChange = (item) => {
      currentHeritage.value = item;
      (form.heritageBatch_ID = item.heritageBatch_ID),
        (form.heritage_ManageOrg = item.heritage_ManageOrg);
      form.heritage_OwnerSuperior = item.heritage_OwnerSuperior;
      form.cityId = item.city_ID;
      form.countryId = item.county_ID;
    };

    const addHistoryProject = () => {
      form.historyProjects.push({});
    };

    const addHistoryFund = () => {
      form.historyFunds.push({});
    };

    const onReviewSubmit = (result) => {
      //console.log(result);
      const f = result ? doSave(false) : Promise.resolve();

      f.then(() => {
        permissionReview(form.id, result).then((p) => {
          emit("change");
          // emit("submit", result);
          router.push("/PermissionPlan/Index");
        });
      })

    };

    const doCodeBind = () => {
      findByCode(form.code).then(() => {
        id.value = form.id;
        if (form.id != 0) {
          router.replace({ params: { id: form.id || 0 } });


        }
        else {
          ElMessage.error({
            message:
              "未找到备案编号的计划书！",
            type: "error",
            duration: 5000,
          });
        }
        if (form.heritageUnit_Id) {
          selectUnitSetting.heritage_Name = form.heritageUnitName;
          findHeritage(form.heritageUnit_Id).then((res) => {
            currentHeritage.value = res;
          });
        }
      })
    }
    const doSave = (submit) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {

            permissionSave().then(() => {
              if (id.value == 0) {
                router.replace({ params: { id: form.id || 0 } });
              }
              id.value = form.id;
              emit("change");
              if (submit) {
                permissionReview(form.id, { result: true }).then((p) => {
                  emit("change");
                  // emit("submit", result);
                  router.push("/PermissionPlan/Index");
                });

              } else {
                fileuploadSetting.typeGroup.list = form.attachList;
                //queryHistory();
              }
              resolve("success");
            });
          } else {
            reject("error");
          }
        });
      });
    };

    const nextTo = () => {
      const errs = [];
      refForm.value.validateField(
        [
          "heritageUnit_Id",
          "heritageBatch_ID",
          "isRevolution",
          "isSafeProject",
          "safeProjectType",
          "projectType",
          "cityId",
          "countryId",
          "address",
          "heritage_ManageOrg",
          "heritage_OwnerSuperior",
        ],
        (valid) => {
          if (valid !== "") {
            errs.push(valid);
          }
        }
      );
      setTimeout(() => {
        if (errs.length == 0) {
          activeName.value = "second";
        }
      }, 1);
    };
    find().then((p) => {
      if (id.value && !p.id) {
        location.href = "/";
        return;
      }

      //form.setupGroup = 1;
      //console.log(user.id, form.createUser, user.inRoles(roles.review));
      if (form.permissionStatus != null) {
        if (form.permissionStatus == 0) {
          if (user.id != form.createUser) {
            location.href = "/";
            return;
          }
        } else if (!user.inRoles(roles.review, roles.prov)) {
          location.href = "/";
          return;
        }
      }
      else if (form.code != null && form.code != '') {
        form.permissionEmpty = false;
      }
      //setTimeout(() => refForm.value.clearValidate(), 1);
      selectUnitSetting.heritage_Name = p.heritageUnitName;
      if (p.heritageUnit_Id) {
        findHeritage(p.heritageUnit_Id).then((res) => {
          currentHeritage.value = res;
        });
      }
      fileuploadSetting.typeGroup.list = form.attachList;
      //console.log(p);
      // console.log(form, p);
      queryHistory();
    });

    const rules = ref({
      // setupFileApply: [
      //   {
      //     required: true, trigger: "blur",
      //     validator: (rule: any, value: any, callback: any) => {
      //       if (!form.attachList || !form.attachList.some(p => p.type == "setupFileApply")) {
      //         callback(new Error("请上传项目计划书!"));
      //       } else {
      //         callback();
      //       }
      //     },
      //   },
      // ],
      heritageUnit_Id: [
        { required: true, message: "请输入文保单位！", trigger: "blur" },
      ],
      heritageBatch_ID: [
        { required: true, message: "请输入批次！", trigger: "change" },
      ],
      isRevolution: [
        { required: true, message: "请输入是否革命文物！", trigger: "change" },
      ],
      isSafeProject: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      safeProjectType: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      projectType: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      cityId: [{ required: true, message: "请输入市级！", trigger: "change" }],
      countryId: [
        { required: true, message: "请输入区县！", trigger: "change" },
      ],
      address: [{ required: true, message: "请输入村镇！", trigger: "blur" }],
      heritage_ManageOrg: [
        { required: true, message: "请输入使用管理单位！", trigger: "blur" },
      ],
      heritage_OwnerSuperior: [
        { required: true, message: "请输入上级主管部门！", trigger: "blur" },
      ],
      project_Name: [
        { required: true, message: "请输入工程对象名称！", trigger: "blur" },
      ],
      heritageUnitDesc: [
        {
          required: true,
          message: "请输入文物保护单位简况！",
          trigger: "blur",
        },
      ],
      projectNeedReason: [
        { required: true, message: "工程实施必要性", trigger: "blur" },
      ],
      projectProtectArea: [
        { required: true, message: "请输入工程防护范围", trigger: "blur" },
      ],
      projectProtectObject: [
        { required: true, message: "请输入工程防护对象", trigger: "blur" },
      ],
      forecastAmount: [{ required: true, message: "请输入", trigger: "blur" }],
      forecastAmountReason: [
        { required: true, message: "请输入测算依据", trigger: "blur" },
      ],
      projectForecastDoThings: [
        { required: true, message: "请输入拟采取的具体措施", trigger: "blur" },
      ],
      projectBaseThingsInfo: [
        { required: true, message: "请输入原有设施简况", trigger: "blur" },
      ],
      projectDoCondition: [
        { required: true, message: "请输入项目实施保障条件", trigger: "blur" },
      ],
      isInProtectPlan: [
        {
          required: true,
          message: "请输入是否纳入保护规划",
          trigger: "change",
        },
      ],
      isDoArcheology: [
        {
          required: true,
          message: "请输入是否开展过考古工作",
          trigger: "change",
        },
      ],
      isDoResearch: [
        {
          required: true,
          message: "请输入是否开展过前期勘察或研究",
          trigger: "change",
        },
      ],
      areaDesc: [
        { required: true, message: "请输入工程范围及规模", trigger: "blur" },
      ],
      techLine: [
        { required: true, message: "请输入工程技术路线", trigger: "blur" },
      ],
      showIdear: [
        { required: true, message: "请输入展示内容策划构想", trigger: "blur" },
      ],
      planDoPeroid: [
        { required: true, message: "请输入拟实施周期", trigger: "blur" },
      ],
      planEndTime: [
        { required: true, message: "请输入计划竣工时间", trigger: "blur" },
      ],
      // planOpenTime: [
      //   { required: true, message: "请输入计划对外开放时间", trigger: "blur" },
      // ],
      resultReview: [
        {
          required: true,
          message: "请输入工程效果预测及文物影响评估",
          trigger: "blur",
        },
      ],
      landCondition: [
        { required: true, message: "请输入土地现状", trigger: "blur" },
      ],
      landOwnType: [
        { required: true, message: "请输入土地权属", trigger: "change" },
      ],
      landManager: [
        { required: true, message: "请输入实际管理部门", trigger: "blur" },
      ],

      landPropType: [
        { required: true, message: "请输入土地性质", trigger: "change" },
      ],
      landPropMemo: [
        { required: true, message: "请输入土地性质备注", trigger: "blur" },
      ],

      forecastBeginAmount: [
        { required: true, message: "请输入前期经费（万元）", trigger: "blur" },
      ],
      forecastDoAmount: [
        {
          required: true,
          message: "请输入工程实施费（万元）",
          trigger: "blur",
        },
      ],
      sourceLocalAmount: [
        {
          required: true,
          message: "请输入地方财政经费（万元）",
          trigger: "blur",
        },
      ],
      sourceCenterAmount: [
        { required: true, message: "请输入国家省级（万元）", trigger: "blur" },
      ],
      sourceElseAmount: [
        { required: true, message: "请输入其他（万元）", trigger: "blur" },
      ],
      otherDesc: [
        {
          required: true,
          message: "请输入其他需要说明的情况",
          trigger: "blur",
        },
      ],
      projectWorkContent: [
        { required: true, message: "请输入工程项目内容", trigger: "blur" },
      ],
      projectWorkPlan: [
        { required: true, message: "请输入工程项目计划", trigger: "blur" },
      ],
      forecastProvAmount: [
        {
          required: true,
          message: "请输入申请省级文物保护补助经费",
          trigger: "blur",
        },
      ],
      contactPerson: [
        {
          required: true,
          message: "请输入联系人及电话",
          trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (
              !form.contactPerson ||
              !form.contactPhone ||
              form.contactPerson === "" ||
              form.contactPhone === ""
            ) {
              callback(new Error("请输入联系人及电话"));
            } else {
              callback();
            }
          },
        },
      ],
      setupDocId: [
        {
          required: true,
          message: "请选择批复文件！",
          trigger: "blur",
        },
      ]

      ,
      planFile: [
        {
          required: true, trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (!form.attachList || !form.attachList.some(p => p.type == "planFile")) {
              callback(new Error("请上传项目方案!"));
            } else {
              callback();
            }
          },
        },
      ],
      setupFile: [
        {
          required: true, trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (!form.attachList || !form.attachList.some(p => p.type == "planFile")) {
              callback(new Error("请上传项目计划书!"));
            } else {
              callback();
            }
          },
        },
      ],
      // attachList: [
      //   {
      //     required: true,
      //     message: "附件是必填项!",
      //     trigger: "blur",
      //     validator: (rule: any, value: any, callback: any) => {
      //       if (!form.attachList || form.attachList.length == 0) {
      //         callback(new Error("附件是必填项!"));
      //       } else {
      //         callback();
      //       }
      //     },
      //   },
      // ],
    });

    return {
      city_IDs,
      doCodeBind,
      isFileUploading,
      nextTo,
      rules,
      logs,
      onReviewSubmit,
      user,
      roles,
      isLoading,
      refForm,
      doSave,
      historyFunds,
      fileuploadSetting,
      parseTime,
      addHistoryProject,
      addHistoryFund,
      scheduleTypeDic,
      dicHeritageClass,
      dicHeritageBatch,
      dicCity,
      dicTown,
      currentHeritage,
      activeName,
      title,
      form,
      selectUnitChange,
      selectUnitSetting,
      dicLabel,
      heritageLevelDic,
      project_ClassifyDic,
      project_SubClassifyDic,
    };
  },
});
</script>

<style lang="scss" scoped>
.permissionPlan .el-form-item .el-form-item {
  margin-bottom: 10px;
}
</style>
